<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="isOld && !disabled && editable"
                  :url="completeUrl"
                  :isSubmit="isComplete"
                  :param="nearAccident"
                  mappingType="PUT"
                  label="완료"
                  icon="check"
                  @beforeAction="completeNearAccident"
                  @btnCallback="completeCallback" 
                />
                <c-btn
                  v-if="isOld && !disabled && editable" 
                  label="삭제" icon="delete_forever" @btnClicked="remove" />
                <c-btn
                  v-show="!disabled && editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="nearAccident"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveNearAccident"
                  @btnCallback="saveCallback" 
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text 
                  required
                  :editable="editable && !nearAccident.iimNearAccidentId" 
                  name="iimNearAccidentName" 
                  label="아차사고명"
                  v-model="nearAccident.iimNearAccidentName"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text 
                  :editable="editable" 
                  :disabled="true"
                  name="iimNearAccidentNo" 
                  label="아차사고번호"
                  v-model="nearAccident.iimNearAccidentNo"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-multi-select
                  required
                  codeGroupCd="IIM_NEAR_KIND_CD"
                  :editable="editable"
                  :disabled="disabled"
                  :isArray="false"
                  itemText="codeName"
                  itemValue="code"
                  name="iimNearKindCds"
                  label="재해유형"
                  v-model="nearAccident.iimNearKindCds"
                >
                </c-multi-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  required
                  :editable="editable"
                  type="datetime"
                  :disabled="disabled"
                  :minuteStep="10"
                  label="사고일시"
                  name="occurrenceDt"
                  v-model="nearAccident.occurrenceDt"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-location
                  :editable="editable"
                  :disabled="disabled"
                  etcFlag="N"
                  label="사고장소"
                  name="locationCd"
                  v-model="nearAccident.locationCd">
                </c-location>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-process
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="공정"
                  name="processCd"
                  v-model="nearAccident.processCd"
                >
                </c-process>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text 
                  :editable="editable"
                  :disabled="disabled"
                  name="occurenceLocation" 
                  label="사고정도"
                  v-model="nearAccident.occurrenceLocation"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text 
                  :editable="editable"
                  :disabled="disabled"
                  name="openning" 
                  label="기인물"
                  v-model="nearAccident.openning"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker 
                  :editable="editable"
                  :disabled="disabled"
                  name="reportDt" 
                  label="배포일자"
                  v-model="nearAccident.reportDt"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant 
                  required
                  :disabled="disabled"
                  :editable="editable" 
                  type="edit" 
                  name="plantCd" 
                  v-model="nearAccident.plantCd" />
              </div>
            </template>  
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="재해자 정보" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-field
                  required
                  :editable="editable"
                  :disabled="disabled"
                  :isFirstValue="false"
                  :data="nearAccident"
                  deptValue="occurrenceDeptCd"
                  label="재해자"
                  type="dept_user"
                  name="accidentUserId"
                  v-model="nearAccident.accidentUserId"
                  @userInfo="accUserDataChange">
                </c-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="입사일자"
                  name="accidentEnterDate"
                  v-model="nearAccident.accidentEnterDate">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="생년월일"
                  name="accidentBirthDate"
                  v-model="nearAccident.accidentBirthDate">
                </c-text>
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="직위"
                  name="accidentSpotName"
                  v-model="nearAccident.accidentSpotName">
                </c-text>
              </div> -->
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="연령/근속년수"
                  name="companyInfo"
                  v-model="companyInfo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  type="edit"
                  :editable="editable"
                  :disabled="disabled"
                  codeGroupCd="IIM_WOUND_CD"
                  itemText="codeName"
                  itemValue="code"
                  name="woundCd"
                  label="상해정도"
                  v-model="nearAccident.woundCd"
                ></c-select>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="재해발생 및 방지대책" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  :rows="5"
                  label="재해발생상황"
                  name="shortPlan"
                  v-model="nearAccident.shortPlan">
                </c-textarea>
              </div>
              <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  :rows="5"
                  label="재해발생원인"
                  name="longPlan"
                  v-model="nearAccident.longPlan">
                </c-textarea>
              </div>
              <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  :rows="5"
                  label="재발방지대책"
                  name="managerOpinion"
                  v-model="nearAccident.managerOpinion">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="tableImpr"
            title="개선 목록"
            :columns="gridImpr.columns"
            :data="nearAccident.imprList"
            :merge="gridImpr.merge"
            selection="multiple"
            rowKey="iimNearCausesId"
            gridHeight="400px"
            :usePaging="false"
            :isExcelDown="false"
            :autoFullHeight="true"
            :filtering="false"
            :columnSetting="false"
            :editable="editable && !disabled"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn v-if="editable && !disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="add" />
                <c-btn v-if="editable && !disabled" label="제외" :showLoading="false" icon="remove" @btnClicked="removeImpr" />
              </q-btn-group>
            </template>
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name==='customCol'">
                <component
                  :is="imprComponent"
                  :col="col"
                  :props="props"
                  :inputEditable="editable&&!disabled"
                  :isImmShow="true"
                  :requestContentsCols="requestContentsCols"
                  tableKey="iimNearCausesId"
                  ibmTaskTypeCd="ITT0000045"
                  ibmTaskUnderTypeCd="ITTU000070"
                  @imprChange="imprChange"
                />
              </template>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="사진추가" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-12">
                <c-upload 
                  :attachInfo="attachInfo1"
                  label="원거리"
                  :editable="editable&&!disabled">
                </c-upload>
              </div>
              <div class="col-12">
                <c-upload 
                  :attachInfo="attachInfo2"
                  label="근거리"
                  :editable="editable&&!disabled">
                </c-upload>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>      
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'nearRegisterInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimNearAccidentId: '',
      }),
    },
  },
  data() {
    return {
      nearAccident: {
        iimNearAccidentId: '', // key
        plantCd: '', // 사업장
        iimNearAccidentNo: '', // 아차사고번호
        iimNearAccidentName: '', // 아차사고명
        iimStatusNearCd: '', // 진행상태
        reportDt: '', // 배포일자
        iimNearKindCds: '', // 재해유형
        occurrenceDt: '', // 발생일시
        occurrenceLocation: '', // 사고장소
        occurrenceDeptCd: '', // 팀명
        openning: '', // 기인물
        processCd: '', // 공정
        shortPlan: '', // 재해발생상황
        longPlan: '', // 재해발생원인
        managerOpinion: '', // 재발방지대책
        accidentUserId: '',  // 사고자
        accidentEnterDate: '',
        accidentBirthDate: '',  
        accidentMobileNo: '',  
        locationCd: '',
        accidentEmpNo: '',  
        accidentSpotName: '',  
        accidentStartDate: '',  // 사고시작일
        accidentEndDate: '',  
        accidentAge: '', 
        accidentLongevityCount: '',
        imprList: [], // 개선목록
        deleteImprList: [], 
      },
      weatherItems: [],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      editable: true,
      saveType: 'POST',
      isSave: false,
      isComplete: false,
      isSaveCause: false,
      saveUrl: '',
      detailUrl: '',
      deleteUrl: '',
      completeUrl: '',
      attachInfo1: {
        isSubmit: '',
        taskClassCd: 'NEAR_FIRST_PICTURE',
        taskKey: '',
      },
      attachInfo2: {
        isSubmit: '',
        taskClassCd: 'NEAR_SECOND_PICTURE',
        taskKey: '',
      },
      gridImpr: {
        columns: [
          {
            required: true,
            name: 'iimNearCauseTypeCd',
            field: 'iimNearCauseTypeCd',
            label: '원인구분',
            align: 'center',
            style: 'width:100px',
            type: 'select',
            codeGroupCd: 'IIM_NEAR_CAUSE_DIVISION_CD',  //IIM_CAUSE_DIVISION_CD
            sortable: false,
          },
          {
            required: true,
            name: 'iimNearAccidentCause',
            field: 'iimNearAccidentCause',
            label: '사고원인',
            align: 'left',
            type: 'text',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'impr',
            field: 'impr',
            label: '원인 및 재발방지 진행',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: '개선번호/개선진행상태',
                align: 'center',
                style: 'width:200px',
                type: 'custom',
                sortable: false
              },
              {
                name: 'iimPreventionMeasures',
                field: 'iimPreventionMeasures',
                label: '개선대책',
                align: 'left',
                style: 'width:400px',
                type: 'text',
                sortable: false
              },
            ]
          },
        ],
        data: [],
      },
    };
  },
  watch: {
  },
  computed: {
    isOld() {
      return Boolean(this.nearAccident.iimNearAccidentId)
    },
    disabled() {
      return (this.editable && Boolean(this.nearAccident.iimStatusNearCd) && this.nearAccident.iimStatusNearCd === 'ISNC000002') || this.nearAccident.approvalStatusCd === 'ASC9999999';
    },
    companyInfo() {
      return this.nearAccident.accidentAge + '/' + this.nearAccident.accidentLongevityCount;
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['iimNearCauseTypeName', 'iimNearAccidentCause'];
    },
    // 개선요청 Component
    imprComponent() {
      return () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.iim.accident.near.get.url;
      this.occurUrl = selectConfig.sop.iim.occurForm.list.url;
      this.saveUrl = transactionConfig.sop.iim.accident.near.insert.url;
      this.deleteUrl = transactionConfig.sop.iim.accident.near.delete.url;
      this.updateCauseUrl = transactionConfig.sop.iim.accident.near.prevention.update.cause.url;
      this.deleteCauseUrl = transactionConfig.sop.iim.accident.near.prevention.delete.url;
      this.completeUrl = transactionConfig.sop.iim.accident.near.complete.url;
      // list setting
      this.getDetail();
    },
    getDetail() {   //상세페이지 항목들에 저장된 값들을 가져오는 부분
      if (this.popupParam.iimNearAccidentId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.iimNearAccidentId);
        this.$http.type = 'GET'; 
        this.$http.request((_result) => {
          this.nearAccident = _result.data;
          this.$set(this.attachInfo1, 'taskKey', this.popupParam.iimNearAccidentId)
          this.$set(this.attachInfo2, 'taskKey', this.popupParam.iimNearAccidentId)
        },);
      } else {
        // nothing...
      }
    },
    dataChange() {  //유효성체크(숫자만 들어오게)
      if(this.nearAccident.frequencyRisk !== null && this.nearAccident.frequencyRisk >= 0 && this.nearAccident.strengthRisk !== null && this.nearAccident.strengthRisk >= 0) {
      this.nearAccident.totalRisk = this.nearAccident.frequencyRisk * this.nearAccident.strengthRisk
      }
    },
    saveNearAccident() {
      if (this.popupParam.iimNearAccidentId) {
        this.saveUrl = transactionConfig.sop.iim.accident.near.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.iim.accident.near.insert.url;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.nearAccident.regUserId = this.$store.getters.user.userId
              this.nearAccident.chgUserId = this.$store.getters.user.userId   
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.popupParam.iimNearAccidentId) {
        this.popupParam.iimNearAccidentId = result.data;
        this.$set(this.attachInfo1, 'taskKey', result.data)
        this.$set(this.attachInfo2, 'taskKey', result.data)
        this.$set(this.attachInfo1, 'isSubmit', uid())
        this.$set(this.attachInfo2, 'isSubmit', uid())
      }
      this.getDetail();
    },
    completeNearAccident() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '완료하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.nearAccident.regUserId = this.$store.getters.user.userId
              this.nearAccident.chgUserId = this.$store.getters.user.userId
              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.iimNearAccidentId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    imprChange() {    
      this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
    },
    saveNearImpr() {
      if (this.$comm.validTable(this.gridImpr.columns, this.nearAccident.imprList)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSaveCause = !this.isSaveCause;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveNearImprCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    add() {
      this.nearAccident.imprList.splice(0, 0, {
        iimNearAccidentId: this.popupParam.iimNearAccidentId,
        iimNearCausesId: uid(),    // uuid와 동일한것
        iimNearCauseTypeCd: null,
        iimNearCauseTypeName: '',
        iimImprovingMeasures: '',
        sopImprovementIds: '', 
        ibmStepNames: '',
        regUserId: this.$store.getters.user.userId, 
        editFlag: 'C',
      })
    },
    removeImpr() {
      let selectData = this.$refs["tableImpr"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "LBLGUIDE", // 안내
          message: "MSGNOITEMS", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.nearAccident.deleteImprList) {
            this.nearAccident.deleteImprList = [];
          }
          if (
            this.$_.findIndex(this.nearAccident.deleteImprList, {
              iimNearCausesId: item.iimNearCausesId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.nearAccident.deleteImprList.push(item);
          }
          this.nearAccident.imprList = this.$_.reject(
            this.nearAccident.imprList,
            item
          );
        });
      }
    },
    accUserDataChange(accUserInfo) {
      if (accUserInfo) {
        this.$set(this.nearAccident, 'accidentSpotName', accUserInfo.spotName);
        this.$set(this.nearAccident, 'accidentEmpNo', accUserInfo.empNo);
        this.$set(this.nearAccident, 'accidentMobileNo', accUserInfo.mobileNo);
        this.$set(this.nearAccident, 'accidentBirthDate', accUserInfo.birthDate);
        this.$set(this.nearAccident, 'accidentEnterDate', accUserInfo.enterDate);
        this.$set(this.nearAccident, 'accidentLongevityCount', accUserInfo.longevityCount);
        this.$set(this.nearAccident, 'accidentAge', accUserInfo.age);
      } else {
        this.$set(this.nearAccident, 'accidentSpotName', '');
        this.$set(this.nearAccident, 'accidentEmpNo', '');
        this.$set(this.nearAccident, 'accidentMobileNo', '');
        this.$set(this.nearAccident, 'accidentBirthDate', '');
        this.$set(this.nearAccident, 'accidentEnterDate', '');
        this.$set(this.nearAccident, 'accidentLongevityCount', '');
        this.$set(this.nearAccident, 'accidentAge', '');
      }
    },
  }
};       
  </script>